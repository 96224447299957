@import url('https://fonts.googleapis.com/css2?family=Chelsea+Market&family=Noto+Serif+JP&family=Alegreya&family=Playfair+Display&display=swap');

.redacted {
	background-color: #222329;
	color: #222329;
	user-select: text;
}

tr {
	vertical-align: top;
}

a {
	text-decoration: none;
	font-weight: 700;
	color: black;
}

a:hover {
	color: lightblue;
}